/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.menu-toggle').click(function(){
          $('.hamburger', this).toggleClass('is-active');
          $('.nav-wrapper').slideToggle(150);
        });

        function smoothScrollHash(hash) {
          var scrolloffset = $(hash).offset().top - $('.nav-primary-sticky .nav-primary').outerHeight();
          if ($('.banner .dropdown-menu.show').length && ($('.banner .dropdown-menu.show').css('position') === 'relative')) {
            scrolloffset -= $('.dropdown-menu.show').outerHeight();
          }
          if ($(hash).length) {
            $('html, body').animate({
                scrollTop: scrolloffset
            }, 900, 'swing');
          }
        }

        $('a[href*="#"]').on('click',function(){
          var hash = $(this).attr('href');
          hash = hash.split('#');
          if (hash) {
            smoothScrollHash('#'+hash[1]);
          }
        });

        if (window.location.hash) {
           smoothScrollHash(window.location.hash);
        }

        function createDownload($baseElem){
          var downloadString = '',
              urlString = '',
              fileNameString = '';
          var $prevSection = $baseElem.closest('section').prev('section');
          if($prevSection.hasClass('section-gaffel-accessoires')){
            $prevSection = $prevSection.prev('section');
          }
          var $parentProduct = $prevSection.find('.producten-serie .list-group-item.active');
          downloadString += $parentProduct.find('.item-product-title').text()+' + ';
          urlString += $parentProduct.find('.product-downloads a').attr('href')+'|';
          fileNameString += $parentProduct.find('.product-downloads a').attr('download')+'|';


          $('.section-gaffel-accessoires .gaffel-accessoires input').each(function(){
            if($(this).prop('checked')){
              var product = $(this).closest('.gaffel-accessoires');
              downloadString += product.data('product')+' + ';
              urlString += product.find('a[download]').attr('href')+'|';
              fileNameString += product.find('a[download]').attr('download')+'|';
            }
          });
          downloadString = downloadString.slice(0, -3);
          urlString = urlString.slice(0, -1);
          fileNameString = fileNameString.slice(0, -1);
          $('.section-gaffel-accessoires .productdownload-span').text(downloadString);
          $('.section-gaffel-accessoires .productdownload-button').data('download', urlString);
          $('.section-gaffel-accessoires .productdownload-button').data('filenames', fileNameString);
          $('.section-gaffel-accessoires .productdownload-span').closest('.section-gaffel-accessoires').show();
        }

        function selectGroup(group){
          $('.section-gaffel-accessoires .product-accessoires-container .gaffel-accessoires').each(function(){
            if($(this).data('group') === 'all'|| group === 'all'){return false;}
            $input = $(this).find('.form-check-input[type="checkbox"]');
            $buttons = $(this).find('a');
            if($(this).data('group') === group || group === '' ){
              $input.closest('.gaffel-accessoires').removeClass('disabled');
              $input.removeAttr('disabled');
              $buttons.removeAttr('disabled');
              $buttons.removeData('disabled');
            }else {
              $input.closest('.gaffel-accessoires').addClass('disabled');
              $input.attr('disabled','disabled');
              $buttons.attr('disabled','disabled');
              $buttons.data('disabled','disabled');
              $input.prop('checked',false);
              $(this).removeClass('active');
            }
          });
        }

        $('main').on('click','.gaffel-accessoires.disabled a',function(e){
          e.preventDefault();
        });

        $('main').on('click','.gaffel-accessoires input',function(e){
            var $baseElem = $(e.target).closest('.gaffel-accessoires'),
                group = $($baseElem).data('group'),
                $container = $baseElem.closest('.product-accessoires-container');

            if($baseElem.data('depth') == '1'){
              $container.find('.gaffel-accessoires.active').removeClass('active');
            }
            if($(e.target).is(":checked")){
              $baseElem.addClass('active');
            }else{
              $baseElem.removeClass('active');
            }

            if($container.closest('main').find('.product-accessoires-input:checked').length === 0){
              selectGroup('');
            }else{
              selectGroup(group);
            }
            createDownload($baseElem);
        });

        $('main').on('click','.productdownload-button', function(e) {
            e.preventDefault();
            var downloads = $(this).data('download').split('|');
            var fileNames = $(this).data('filenames').split('|');
            var downloadNames = $(this).closest('.product-accessoires-container').find('.productdownload-span').text().split(' + ');

            for (var i = 0; i < downloads.length; i++) {
              var url = downloads[i];
              var a = document.createElement("a");
              a.setAttribute('href', url);
              a.setAttribute('download', fileNames[i]);
              a.setAttribute('target', '_blank');
              a.click();
            }
        });

        // Ajax function get posts
        function ajaxFunction($params, $container,contentTypeParent) {
          $container.append('<div class="ajax-status flex-center"></div>');
          $status = $container.find('.ajax-status');
          $status.html('<div class="spinner-border text-secondary" role="status"><span class="sr-only">Loading...</span></div>');
          $.ajax(
          {
            url: bobz.ajax_url,
            data: {
              action: 'ajax_functions',
              nonce: bobz.nonce,
              params: $params
            },
            type: 'post',
            dataType: 'json',
            success: function(data, textStatus, XMLHttpRequest) {
              if (data.status === 200) {
                if($container.hasClass("section")){
                  $container.parent().find('.section-gaffel-accessoires').remove();
                  $container.after(data.content);
                }else{
                  $container.html(data.content);
                }
                if(contentTypeParent === 'schroefdraad'){

                  $container.find('.list-group .list-group-item:first-child').click();
                }
                $('.ajax-status').remove();
                $('[data-toggle="tooltip"]').tooltip();
                // Fill the product selectbox with options
              }
              else {
                $status.html(data.message);
              }
            },
            error: function(MLHttpRequest, textStatus, errorThrown) {
              $('.ajax-status').remove();
              $container.html(textStatus);
            },
            complete: function(data, textStatus) {
              $('.ajax-status').remove();
            }
          });
        }

        function setAjaxFunction($elem,$container,contentTypeParent) {
           var ajaxData = $elem.data('ajax');
           ajaxFunction(ajaxData, $container,contentTypeParent);
        }

        $('.productserie-row').on('click','.list-group-item',function(e){
          var $product = $(this),
              $productenSerie = $product.closest('.producten-serie'),
              $productSection = $product.closest('.section'),
              $productGroupContainer = $productenSerie.parent().find('.product-info-group-container'),
              $productInfoContainer = $productGroupContainer.find('.product-info-container'),
              $productMeta = $product.find('.item-product-meta'),
              isProductMetaEmpty = ($productMeta.find('.product-info').children().length === 0);
          
          $('section').find('.list-group-item.active').removeClass('active');
          if($('section').find('.product-info').length > 1){
             $('section').find('.product-info:not(:last-child)').remove();
          }

          if(!isProductMetaEmpty) {
            $productInfoContainer.prepend($productMeta.html());
            $product.addClass('active');
          }
          setAjaxFunction($product.find('.item-product-title'),$productSection,'product');
        });

        $('.select-schroefdraad-producten').on('click','li',function(e){
          var schroefdraad = $(this).data('target'),
              serie = $(this).data('serie-id'),
              $productSerie = $(this).closest('section').find('.productserie-row '+serie),
              type = 'schroefdraad';

          if(e.isTrigger) {
            type = '';
          }

          setAjaxFunction($(this),$productSerie,type);

          $(this).parent().find('li').removeClass('active');
          $(this).addClass('active');
          
        });

        $('.select-schroefdraad-producten li:first-child').click();

        $('[data-toggle="tooltip"]').tooltip();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
